import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeModeProvider } from './contexts/ThemeModeContext';
import { useThemeMode } from './hooks/useThemeMode';
import Header from './components/Header/Header';
import Home from './pages/Home';
import { useTranslation } from 'react-i18next';
import './i18n/config';
import Layout from './components/Layout';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from '@vercel/analytics/react';

const ThemedApp: React.FC = () => {
  const { mode } = useThemeMode();
  const { i18n } = useTranslation();

  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#0070F3',
        light: '#338FFF',
        dark: '#0051B3',
      },
      secondary: {
        main: '#7928CA',
        light: '#9B4EE3',
        dark: '#5B1E9B',
      },
      background: {
        default: mode === 'light' ? '#FAFAFA' : '#0A0A0A',
        paper: mode === 'light' ? '#FFFFFF' : '#1A1A1A',
      },
      text: {
        primary: mode === 'light' ? '#1A1A1A' : '#FFFFFF',
        secondary: mode === 'light' ? '#666666' : '#A0A0A0',
      },
    },
    typography: {
      fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  });

  useEffect(() => {
    // Recupera o idioma salvo no localStorage ou usa o padrão do navegador
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>
        <Header />
        <Home />
        <SpeedInsights />
        <Analytics
          beforeSend={(event) => {
            // Adiciona informações adicionais ao evento
            return {
              ...event,
              theme: mode,
              language: i18n.language,
              // Você pode adicionar mais informações personalizadas aqui
            };
          }}
          debug={process.env.NODE_ENV === 'development'}
        />
      </Layout>
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <ThemeModeProvider>
      <ThemedApp />
    </ThemeModeProvider>
  );
};

export default App;
