import React from 'react';
import { Box, Container, Typography, Button, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LinkedIn, GitHub, Email } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ScrollReveal from '../ScrollReveal';

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
}));

const ContactButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  borderRadius: theme.shape.borderRadius * 2,
  fontSize: '1.1rem',
  fontWeight: 600,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  width: '100%',
  '&:hover': {
    textDecoration: 'none',
  },
});

const Contact: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section id="contact">
      <Container maxWidth="lg">
        <ScrollReveal>
          <GradientText variant="h2" align="center" gutterBottom>
            {t('contact.title')}
          </GradientText>
        </ScrollReveal>

        <ScrollReveal delay={200}>
          <Typography
            variant="body1"
            align="center"
            paragraph
            sx={{ mb: 8, maxWidth: '800px', mx: 'auto' }}
          >
            {t('contact.description')}
          </Typography>
        </ScrollReveal>

        <ScrollReveal delay={400}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <StyledLink
                href="https://www.linkedin.com/in/dev-angelo-castro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<LinkedIn />}
                >
                  LinkedIn
                </ContactButton>
              </StyledLink>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledLink
                href="https://github.com/AngeloCastro9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<GitHub />}
                >
                  GitHub
                </ContactButton>
              </StyledLink>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledLink
                href="mailto:castromatheus060@gmail.com"
              >
                <ContactButton
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<Email />}
                >
                  Email
                </ContactButton>
              </StyledLink>
            </Grid>
          </Grid>
        </ScrollReveal>
      </Container>
    </Section>
  );
};

export default Contact; 