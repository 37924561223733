import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Brightness4, Brightness7, Menu as MenuIcon } from '@mui/icons-material';
import { useThemeMode } from '../../hooks/useThemeMode';
import { useAnalytics } from '../../hooks/useAnalytics';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

const NavButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileMenu = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: 65,
  '& .MuiSelect-select': {
    padding: '4px 8px',
    paddingRight: '24px !important',
  },
}));

const FlagIcon = styled('img')({
  width: '32px',
  height: '24px',
  borderRadius: '4px',
  objectFit: 'cover',
  display: 'block',
});

const LanguageMenuItem = styled(MenuItem)({
  padding: '8px',
  minHeight: 'unset',
  display: 'flex',
  justifyContent: 'center',
});

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { toggleTheme } = useThemeMode();
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { trackClick, trackEvent } = useAnalytics();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      trackClick(`nav-${id}`, 'navigation');
    }
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
    trackEvent({
      name: 'mobile_menu_open',
      properties: {
        screenWidth: window.innerWidth,
      },
    });
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    const newLang = event.target.value as string;
    i18n.changeLanguage(newLang);
    localStorage.setItem('i18nextLng', newLang);
    trackEvent({
      name: 'language_change',
      properties: {
        from: i18n.language,
        to: newLang,
      },
    });
  };

  const handleThemeToggle = () => {
    toggleTheme();
    trackEvent({
      name: 'theme_toggle',
      properties: {
        newTheme: theme.palette.mode === 'light' ? 'dark' : 'light',
      },
    });
  };

  const renderFlag = (lang: string) => (
    <FlagIcon
      src={`https://flagcdn.com/w40/${lang === 'en' ? 'us' : 'br'}.png`}
      alt={lang === 'en' ? 'English' : 'Português'}
    />
  );

  return (
    <AppBar position="sticky" color="default" elevation={0}>
      <StyledToolbar>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Angelo Castro
        </Typography>
        
        <NavButtons>
          <Button color="inherit" onClick={() => scrollToSection('about')}>
            {t('header.about')}
          </Button>
          <Button color="inherit" onClick={() => scrollToSection('skills')}>
            {t('header.skills')}
          </Button>
          <Button color="inherit" onClick={() => scrollToSection('projects')}>
            {t('header.projects')}
          </Button>
          <Button color="inherit" onClick={() => scrollToSection('contact')}>
            {t('header.contact')}
          </Button>
          
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', ml: 2 }}>
            <StyledSelect
              value={i18n.language}
              onChange={handleLanguageChange}
              variant="standard"
              sx={{ mr: 1 }}
              renderValue={(value) => renderFlag(value as string)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiList-root': {
                      padding: 0,
                    },
                  },
                },
              }}
            >
              <LanguageMenuItem value="pt">
                {renderFlag('pt')}
              </LanguageMenuItem>
              <LanguageMenuItem value="en">
                {renderFlag('en')}
              </LanguageMenuItem>
            </StyledSelect>
            
            <IconButton onClick={handleThemeToggle} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
          </Box>
        </NavButtons>

        <MobileMenu>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <StyledSelect
              value={i18n.language}
              onChange={handleLanguageChange}
              variant="standard"
              sx={{ mr: 1 }}
              renderValue={(value) => renderFlag(value as string)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiList-root': {
                      padding: 0,
                    },
                  },
                },
              }}
            >
              <LanguageMenuItem value="pt">
                {renderFlag('pt')}
              </LanguageMenuItem>
              <LanguageMenuItem value="en">
                {renderFlag('en')}
              </LanguageMenuItem>
            </StyledSelect>
            
            <IconButton onClick={handleThemeToggle} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
              edge="end"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </MobileMenu>

        <Menu
          anchorEl={mobileMenuAnchor}
          open={Boolean(mobileMenuAnchor)}
          onClose={handleMobileMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => scrollToSection('about')}>
            {t('header.about')}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('skills')}>
            {t('header.skills')}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('projects')}>
            {t('header.projects')}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('contact')}>
            {t('header.contact')}
          </MenuItem>
        </Menu>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header; 