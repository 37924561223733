import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ProjectCard from '../ProjectCard';
import ScrollReveal from '../ScrollReveal';
import { projects } from '../../data/projects';

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  minHeight: '100vh',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)'
    : 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 100%)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: theme.palette.mode === 'dark'
      ? 'linear-gradient(90deg, transparent, rgba(255,255,255,0.1), transparent)'
      : 'linear-gradient(90deg, transparent, rgba(0,0,0,0.1), transparent)',
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
}));

const ProjectsGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > .MuiGrid-item': {
    display: 'flex',
    '& > div': { // ScrollReveal container
      width: '100%',
      '& > div': { // ProjectCard container
        height: '100%',
      },
    },
  },
}));

const Projects: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section id="projects">
      <Container maxWidth="lg">
        <ScrollReveal>
          <GradientText
            variant="h2"
            align="center"
            gutterBottom
            sx={{ mb: 6 }}
          >
            {t('projects.title')}
          </GradientText>
        </ScrollReveal>
        
        <ScrollReveal delay={200}>
          <Typography
            variant="body1"
            align="center"
            paragraph
            sx={{ mb: 8, maxWidth: '800px', mx: 'auto' }}
          >
            {t('projects.description')}
          </Typography>
        </ScrollReveal>

        <ProjectsGrid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item key={project.title} xs={12} sm={6} md={6}>
              <ScrollReveal delay={300 + index * 100}>
                <ProjectCard project={project} />
              </ScrollReveal>
            </Grid>
          ))}
        </ProjectsGrid>
      </Container>
    </Section>
  );
};

export default Projects; 