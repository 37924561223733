import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { skillsData } from '../../data/skills';

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  minHeight: '100vh',
  position: 'relative',
  zIndex: 1,
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
}));

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const SkillIcon = styled('img')(({ theme }) => ({
  width: '48px',
  height: '48px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    animation: `${floatAnimation} 1s ease-in-out infinite`,
  },
}));

const SkillCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  fontWeight: 600,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: 0,
    width: '40px',
    height: '4px',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    borderRadius: '2px',
  },
}));

const SkillName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.875rem',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Skills: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section id="skills">
      <Container maxWidth="lg">
        <GradientText
          variant="h2"
          align="center"
          gutterBottom
          sx={{ mb: 6 }}
        >
          {t('skills.title')}
        </GradientText>
        <Typography
          variant="body1"
          align="center"
          paragraph
          sx={{ mb: 8, maxWidth: '800px', mx: 'auto' }}
        >
          {t('skills.description')}
        </Typography>

        <Grid container spacing={4}>
          {skillsData.map((category, index) => (
            <Grid item xs={12} md={6} key={index}>
              <SkillCard elevation={4}>
                <CategoryTitle variant="h5">
                  {t(`skills.categories.${category.title}`)}
                </CategoryTitle>
                <Grid container spacing={3}>
                  {category.skills.map((skill, skillIndex) => (
                    <Grid item xs={4} sm={3} key={skillIndex} sx={{ textAlign: 'center' }}>
                      <SkillIcon
                        src={skill.icon}
                        alt={skill.name}
                        title={skill.name}
                        loading="lazy"
                      />
                      <SkillName>{skill.name}</SkillName>
                    </Grid>
                  ))}
                </Grid>
              </SkillCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Skills; 