export interface Project {
  title: string;
  description: string;
  image: string;
  link: string;
  technologies: string[];
}

export const projects: Project[] = [
  {
    title: 'BST',
    description: 'Implementação de uma Binary Search Tree (Árvore de Busca Binária) em Python, demonstrando conhecimentos em estruturas de dados e algoritmos.',
    image: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg',
    link: 'https://github.com/AngeloCastro9/BST',
    technologies: ['Python', 'Algorithms', 'Data Structures']
  },
  {
    title: 'CleanTrash',
    description: 'Projeto desenvolvido em C#, focado em práticas de Clean Code e organização de código.',
    image: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg',
    link: 'https://github.com/AngeloCastro9/CleanTrash',
    technologies: ['C#', 'Clean Code', '.NET']
  },
  {
    title: 'Cripto',
    description: 'Projeto desenvolvido em Dart, explorando conceitos de criptografia e segurança.',
    image: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dart/dart-original.svg',
    link: 'https://github.com/AngeloCastro9/cripto',
    technologies: ['Dart', 'Cryptography', 'Security']
  },
  {
    title: 'K-means',
    description: 'Implementação do algoritmo de clustering K-means em TypeScript, demonstrando conhecimentos em machine learning e análise de dados.',
    image: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg',
    link: 'https://github.com/AngeloCastro9/kmeans',
    technologies: ['TypeScript', 'Machine Learning', 'Data Analysis']
  },
  {
    title: 'Tic Tac Toe IA',
    description: 'Jogo da velha com inteligência artificial implementado em Python, explorando conceitos de IA e algoritmos de jogos.',
    image: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg',
    link: 'https://github.com/AngeloCastro9/Tic_Tac_Toe_IA',
    technologies: ['Python', 'AI', 'Game Development']
  },
  {
    title: 'nCopy',
    description: 'Utilitário desenvolvido em C# para operações de cópia e manipulação de arquivos.',
    image: 'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/csharp/csharp.png',
    link: 'https://github.com/AngeloCastro9/nCopy',
    technologies: ['C#', '.NET', 'File System']
  }
]; 