import { useCallback } from 'react';

type VercelAnalyticsEvent = 'event' | 'pageview' | 'beforeSend';

interface AnalyticsEvent {
  name: string;
  properties?: Record<string, any>;
}

export const useAnalytics = () => {
  const trackEvent = useCallback(({ name, properties = {} }: AnalyticsEvent) => {
    try {
      // Log to console in development
      if (process.env.NODE_ENV === 'development') {
        console.log(`[Analytics Event] ${name}:`, properties);
      }

      // Track event using window.va (Vercel Analytics)
      if (typeof window !== 'undefined' && window.va) {
        window.va('event', {
          name,
          ...properties,
        });
      }
    } catch (error) {
      console.error('[Analytics Error]:', error);
    }
  }, []);

  const trackPageView = useCallback((path: string) => {
    trackEvent({
      name: 'page_view',
      properties: {
        path,
        title: document.title,
        referrer: document.referrer,
      },
    });
  }, [trackEvent]);

  const trackClick = useCallback((elementId: string, elementType: string) => {
    trackEvent({
      name: 'click',
      properties: {
        elementId,
        elementType,
        path: window.location.pathname,
      },
    });
  }, [trackEvent]);

  const trackError = useCallback((error: Error, componentName?: string) => {
    trackEvent({
      name: 'error',
      properties: {
        message: error.message,
        stack: error.stack,
        componentName,
        path: window.location.pathname,
      },
    });
  }, [trackEvent]);

  const trackPerformance = useCallback((metric: { name: string; value: number }) => {
    trackEvent({
      name: 'performance',
      properties: {
        metricName: metric.name,
        value: metric.value,
        path: window.location.pathname,
      },
    });
  }, [trackEvent]);

  return {
    trackEvent,
    trackPageView,
    trackClick,
    trackError,
    trackPerformance,
  };
};

// Adiciona tipos para o objeto window
declare global {
  interface Window {
    va?: (event: VercelAnalyticsEvent, properties?: unknown) => void;
  }
} 