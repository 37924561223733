import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import AnimatedBackground from '../AnimatedBackground';
import DynamicGradient from '../DynamicGradient';
import CodeBackground from '../CodeBackground';

const ContentWrapper = styled(Box)({
  position: 'relative',
  minHeight: '100vh',
  zIndex: 2,
});

const BackgroundWrapper = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  overflow: 'hidden',
});

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <BackgroundWrapper>
        <DynamicGradient />
        <AnimatedBackground />
        <CodeBackground />
      </BackgroundWrapper>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Box>
  );
};

export default Layout; 