import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';

interface ScrollRevealProps {
  children: React.ReactNode;
  delay?: number;
  duration?: number;
  distance?: string;
  origin?: 'top' | 'right' | 'bottom' | 'left';
}

const RevealContainer = styled('div')<{
  $delay?: number;
  $duration?: number;
  $distance?: string;
  $origin?: string;
}>(({ $delay = 0, $duration = 1000, $distance = '50px', $origin = 'bottom' }) => ({
  opacity: 0,
  transform: `translate${$origin === 'left' || $origin === 'right' ? 'X' : 'Y'}(${
    $origin === 'left' || $origin === 'top' ? $distance : `-${$distance}`
  })`,
  transition: `opacity ${$duration}ms ease-out, transform ${$duration}ms ease-out`,
  transitionDelay: `${$delay}ms`,
  '&.visible': {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
}));

const ScrollReveal: React.FC<ScrollRevealProps> = ({
  children,
  delay = 0,
  duration = 1000,
  distance = '50px',
  origin = 'bottom',
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            element.classList.add('visible');
            observer.unobserve(element);
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '50px',
      }
    );

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <RevealContainer
      ref={elementRef}
      $delay={delay}
      $duration={duration}
      $distance={distance}
      $origin={origin}
    >
      {children}
    </RevealContainer>
  );
};

export default ScrollReveal; 