import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 3,
  overflow: 'hidden',
  opacity: theme.palette.mode === 'dark' ? 0.07 : 0.04,
  pointerEvents: 'none',
}));

const CodeText = styled('pre')({
  margin: 0,
  padding: '20px',
  fontFamily: '"Fira Code", monospace',
  fontSize: '14px',
  lineHeight: '1.5',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
  animation: 'scrollCode 60s linear infinite',
  '@keyframes scrollCode': {
    '0%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(-50%)',
    },
  },
});

const codeSnippets = [
  `function quickSort(arr: number[]): number[] {
    if (arr.length <= 1) return arr;
    
    const pivot = arr[Math.floor(arr.length / 2)];
    const left = arr.filter(x => x < pivot);
    const middle = arr.filter(x => x === pivot);
    const right = arr.filter(x => x > pivot);
    
    return [...quickSort(left), ...middle, ...quickSort(right)];
  }`,
  `interface TreeNode<T> {
    value: T;
    left?: TreeNode<T>;
    right?: TreeNode<T>;
  }
  
  class BinarySearchTree<T> {
    root?: TreeNode<T>;
    
    insert(value: T): void {
      if (!this.root) {
        this.root = { value };
        return;
      }
      this.insertNode(this.root, value);
    }
  }`,
  `async function fetchData<T>(url: string): Promise<T> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }`,
  `const memoize = <T extends (...args: any[]) => any>(
    fn: T
  ): T => {
    const cache = new Map();
    
    return ((...args: Parameters<T>): ReturnType<T> => {
      const key = JSON.stringify(args);
      if (cache.has(key)) {
        return cache.get(key);
      }
      const result = fn(...args);
      cache.set(key, result);
      return result;
    }) as T;
  };`,
];

const CodeBackground: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const updateHeight = () => {
      const height = window.innerHeight;
      container.style.height = `${height}px`;
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <CodeText>
        {Array(3).fill(codeSnippets).flat().join('\n\n')}
      </CodeText>
    </Container>
  );
};

export default CodeBackground; 