import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  IconButton,
  CardActions,
  useTheme,
} from '@mui/material';
import { GitHub } from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';
import { Project } from '../../data/projects';
import { useAnalytics } from '../../hooks/useAnalytics';

interface ProjectCardProps {
  project: Project;
}

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.1)'}`,
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8],
    '& .project-image': {
      animation: `${pulse} 2s ease-in-out infinite`,
    },
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 0, 2),
  flex: '0 0 auto',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '56.25%', // 16:9 aspect ratio
  backgroundColor: theme.palette.mode === 'dark' ? '#1A1A1A' : '#F5F5F5',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(45deg, ${theme.palette.primary.main}20, ${theme.palette.secondary.main}20)`,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::after': {
    opacity: 1,
  },
}));

const ProjectImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  padding: '16px',
  transition: 'transform 0.3s ease-in-out',
});

const CardBody = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TechStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: 'auto',
  paddingTop: theme.spacing(2),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
}));

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const theme = useTheme();
  const { trackEvent } = useAnalytics();

  const handleProjectClick = () => {
    trackEvent({
      name: 'project_click',
      properties: {
        projectTitle: project.title,
        projectLink: project.link,
        technologies: project.technologies,
      },
    });
  };

  const handleTechClick = (tech: string) => {
    trackEvent({
      name: 'technology_click',
      properties: {
        technology: tech,
        projectTitle: project.title,
      },
    });
  };

  return (
    <StyledCard>
      <CardHeader>
        <ImageContainer>
          <ProjectImage
            src={project.image}
            alt={project.title}
            className="project-image"
          />
        </ImageContainer>
      </CardHeader>
      <CardBody>
        <Typography 
          variant="h6" 
          gutterBottom 
          component="h3"
          sx={{
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 700,
          }}
        >
          {project.title}
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          paragraph
          sx={{ mb: 'auto' }}
        >
          {project.description}
        </Typography>
        <TechStack>
          {project.technologies.map((tech) => (
            <StyledChip
              key={tech}
              label={tech}
              size="small"
              variant="outlined"
              onClick={() => handleTechClick(tech)}
              sx={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: `${theme.palette.primary.main}10`,
                },
              }}
            />
          ))}
        </TechStack>
      </CardBody>
      <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
        <IconButton
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub repository"
          onClick={handleProjectClick}
          sx={{
            color: theme.palette.primary.main,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.1) rotate(8deg)',
              backgroundColor: `${theme.palette.primary.main}10`,
            },
          }}
        >
          <GitHub />
        </IconButton>
      </CardActions>
    </StyledCard>
  );
};

export default ProjectCard; 