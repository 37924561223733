import React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { GitHub, LinkedIn, Code, Psychology } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Projects from '../../components/Projects';
import Skills from '../../components/Skills';
import Contact from '../../components/Contact/Contact';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at 50% 50%, ${theme.palette.primary.main}15, transparent 50%)`,
    zIndex: 0,
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  animation: `${fadeIn} 1s ease-out`,
}));

const HighlightText = styled('span')(({ theme }) => ({
  background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  fontWeight: 700,
}));

const SocialLink = styled('a')({
  textDecoration: 'none',
  display: 'inline-block',
  margin: '0.5rem',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
  },
});

const AnimatedButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '100%',
    height: '100%',
    background: `linear-gradient(120deg, transparent, ${theme.palette.primary.main}30, transparent)`,
    transition: 'all 0.5s ease',
  },
  '&:hover::after': {
    left: '100%',
  },
}));

const Home: React.FC = () => {
  const { t } = useTranslation();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HeroSection id="about">
        <Container maxWidth="lg">
          <ContentWrapper>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography 
                  variant="h6" 
                  gutterBottom 
                  sx={{ 
                    opacity: 0,
                    animation: `${fadeIn} 0.5s ease-out forwards`,
                  }}
                >
                  {t('hero.greeting')}
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h1" 
                  gutterBottom 
                  sx={{ 
                    fontWeight: 700,
                    opacity: 0,
                    animation: `${fadeIn} 0.5s ease-out 0.2s forwards`,
                  }}
                >
                  Angelo Castro
                </Typography>
                <Typography 
                  variant="h3" 
                  gutterBottom 
                  sx={{ 
                    fontWeight: 500,
                    opacity: 0,
                    animation: `${fadeIn} 0.5s ease-out 0.4s forwards`,
                  }}
                >
                  <HighlightText>{t('hero.role')}</HighlightText>
                </Typography>
                <Typography 
                  variant="body1" 
                  paragraph 
                  sx={{ 
                    fontSize: '1.2rem', 
                    maxWidth: '800px', 
                    mb: 4, 
                    lineHeight: 1.8,
                    opacity: 0,
                    animation: `${fadeIn} 0.5s ease-out 0.6s forwards`,
                  }}
                >
                  {t('hero.description')}
                </Typography>
                
                <Box sx={{ 
                  mt: 4,
                  opacity: 0,
                  animation: `${fadeIn} 0.5s ease-out 0.8s forwards`,
                }}>
                  <SocialLink 
                    href="https://github.com/AngeloCastro9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AnimatedButton
                      variant="contained"
                      color="primary"
                      startIcon={<GitHub />}
                    >
                      {t('footer.socialLinks.github')}
                    </AnimatedButton>
                  </SocialLink>
                  <SocialLink
                    href="https://www.linkedin.com/in/dev-angelo-castro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AnimatedButton
                      variant="outlined"
                      color="primary"
                      startIcon={<LinkedIn />}
                    >
                      {t('footer.socialLinks.linkedin')}
                    </AnimatedButton>
                  </SocialLink>
                  <SocialLink href="#skills" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('skills');
                  }}>
                    <AnimatedButton
                      variant="outlined"
                      color="secondary"
                      startIcon={<Psychology />}
                    >
                      {t('header.skills')}
                    </AnimatedButton>
                  </SocialLink>
                  <SocialLink href="#projects" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('projects');
                  }}>
                    <AnimatedButton
                      variant="outlined"
                      color="secondary"
                      startIcon={<Code />}
                    >
                      {t('header.projects')}
                    </AnimatedButton>
                  </SocialLink>
                </Box>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Container>
      </HeroSection>
      <Skills />
      <Projects />
      <Contact />
    </>
  );
};

export default Home; 