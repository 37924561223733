import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const GradientContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  opacity: theme.palette.mode === 'dark' ? 0.25 : 0.2,
  pointerEvents: 'none',
  overflow: 'hidden',
}));

const GradientBlob = styled('div')({
  position: 'absolute',
  borderRadius: '50%',
  filter: 'blur(80px)',
  transition: 'all 0.3s ease-in-out',
});

interface Blob {
  x: number;
  y: number;
  size: number;
  vx: number;
  vy: number;
  color: string;
}

const DynamicGradient: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const blobsRef = useRef<Blob[]>([]);
  const frameRef = useRef<number>(0);
  const theme = useTheme();

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const colors = theme.palette.mode === 'dark' 
      ? [
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.primary.light,
          theme.palette.secondary.light,
        ]
      : [
          theme.palette.primary.dark,
          theme.palette.secondary.dark,
          theme.palette.primary.main,
          theme.palette.secondary.main,
        ];

    const createBlob = (): Blob => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      size: Math.random() * 300 + 200,
      vx: (Math.random() - 0.5) * 0.5,
      vy: (Math.random() - 0.5) * 0.5,
      color: colors[Math.floor(Math.random() * colors.length)],
    });

    const initBlobs = () => {
      blobsRef.current = Array.from({ length: 4 }, createBlob);
      updateBlobElements();
    };

    const updateBlobElements = () => {
      container.innerHTML = '';
      blobsRef.current.forEach((blob, index) => {
        const element = document.createElement('div');
        Object.assign(element.style, {
          width: `${blob.size}px`,
          height: `${blob.size}px`,
          left: `${blob.x}px`,
          top: `${blob.y}px`,
          background: blob.color,
          position: 'absolute',
          borderRadius: '50%',
          filter: 'blur(80px)',
          transform: 'translate(-50%, -50%)',
          opacity: '0.5',
        });
        container.appendChild(element);
      });
    };

    const animate = () => {
      blobsRef.current.forEach((blob) => {
        blob.x += blob.vx;
        blob.y += blob.vy;

        // Bounce off edges
        if (blob.x < 0 || blob.x > window.innerWidth) {
          blob.vx *= -1;
          blob.x = Math.max(0, Math.min(blob.x, window.innerWidth));
        }
        if (blob.y < 0 || blob.y > window.innerHeight) {
          blob.vy *= -1;
          blob.y = Math.max(0, Math.min(blob.y, window.innerHeight));
        }
      });

      updateBlobElements();
      frameRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      blobsRef.current.forEach((blob) => {
        blob.x = Math.min(blob.x, window.innerWidth);
        blob.y = Math.min(blob.y, window.innerHeight);
      });
      updateBlobElements();
    };

    window.addEventListener('resize', handleResize);
    initBlobs();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(frameRef.current);
    };
  }, [theme]);

  return <GradientContainer ref={containerRef} />;
};

export default DynamicGradient; 