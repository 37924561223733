import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const Canvas = styled('canvas')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 0,
  opacity: theme.palette.mode === 'dark' ? 0.3 : 0.2,
  pointerEvents: 'none',
}));

interface Point {
  x: number;
  y: number;
  vx: number;
  vy: number;
  radius: number;
  color: string;
  alpha: number;
  pulseSpeed: number;
  pulseDirection: number;
}

const AnimatedBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pointsRef = useRef<Point[]>([]);
  const frameRef = useRef<number>(0);
  const mouseRef = useRef({ x: 0, y: 0 });
  const theme = useTheme();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const getRandomColor = () => {
      const colors = theme.palette.mode === 'dark'
        ? [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.primary.light,
            theme.palette.secondary.light,
          ]
        : [
            theme.palette.primary.dark,
            theme.palette.secondary.dark,
            theme.palette.primary.main,
            theme.palette.secondary.main,
          ];
      return colors[Math.floor(Math.random() * colors.length)];
    };

    const resizeCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      
      ctx.scale(dpr, dpr);
      canvas.style.width = `${rect.width}px`;
      canvas.style.height = `${rect.height}px`;
    };

    const initPoints = () => {
      const points: Point[] = [];
      const numPoints = Math.floor((window.innerWidth * window.innerHeight) / 10000);
      
      for (let i = 0; i < numPoints; i++) {
        points.push({
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
          vx: (Math.random() - 0.5) * 0.8,
          vy: (Math.random() - 0.5) * 0.8,
          radius: Math.random() * 2 + 1,
          color: getRandomColor(),
          alpha: Math.random() * 0.5 + 0.2,
          pulseSpeed: Math.random() * 0.02 + 0.01,
          pulseDirection: 1,
        });
      }
      
      pointsRef.current = points;
    };

    const drawLines = (point: Point, points: Point[], mouseDistance: number) => {
      points.forEach((p) => {
        const distance = Math.sqrt(
          Math.pow(point.x - p.x, 2) + Math.pow(point.y - p.y, 2)
        );

        const maxDistance = mouseDistance ? 200 : 150;
        if (distance < maxDistance && point !== p) {
          const alpha = mouseDistance 
            ? (1 - distance / maxDistance) * 0.5 
            : (1 - distance / maxDistance) * 0.3;

          ctx.beginPath();
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(p.x, p.y);
          
          const gradient = ctx.createLinearGradient(point.x, point.y, p.x, p.y);
          gradient.addColorStop(0, `${point.color}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`);
          gradient.addColorStop(1, `${p.color}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`);
          
          ctx.strokeStyle = gradient;
          ctx.stroke();
        }
      });
    };

    const updatePoint = (point: Point) => {
      // Update position
      point.x += point.vx;
      point.y += point.vy;

      // Bounce off edges
      if (point.x < 0 || point.x > canvas.width) {
        point.vx *= -1;
        point.x = Math.max(0, Math.min(point.x, canvas.width));
      }
      if (point.y < 0 || point.y > canvas.height) {
        point.vy *= -1;
        point.y = Math.max(0, Math.min(point.y, canvas.height));
      }

      // Pulse effect
      point.alpha += point.pulseSpeed * point.pulseDirection;
      if (point.alpha >= 0.7) {
        point.pulseDirection = -1;
      } else if (point.alpha <= 0.2) {
        point.pulseDirection = 1;
      }

      // Mouse interaction
      const dx = mouseRef.current.x - point.x;
      const dy = mouseRef.current.y - point.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance < 200) {
        const force = (200 - distance) / 200;
        point.vx -= (dx / distance) * force * 0.02;
        point.vy -= (dy / distance) * force * 0.02;
      }

      // Speed limit
      const speed = Math.sqrt(point.vx * point.vx + point.vy * point.vy);
      if (speed > 2) {
        point.vx = (point.vx / speed) * 2;
        point.vy = (point.vy / speed) * 2;
      }
    };

    const animate = () => {
      if (!ctx || !canvas) return;
      
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 1;

      pointsRef.current.forEach((point) => {
        updatePoint(point);

        // Draw point
        ctx.beginPath();
        ctx.arc(point.x, point.y, point.radius, 0, Math.PI * 2);
        ctx.fillStyle = `${point.color}${Math.floor(point.alpha * 255).toString(16).padStart(2, '0')}`;
        ctx.fill();

        // Draw connections
        const mouseDistance = Math.sqrt(
          Math.pow(mouseRef.current.x - point.x, 2) + 
          Math.pow(mouseRef.current.y - point.y, 2)
        );
        drawLines(point, pointsRef.current, mouseDistance);
      });

      frameRef.current = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const rect = canvas.getBoundingClientRect();
      mouseRef.current = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    };

    window.addEventListener('resize', resizeCanvas);
    window.addEventListener('mousemove', handleMouseMove);
    
    resizeCanvas();
    initPoints();
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(frameRef.current);
    };
  }, [theme.palette.mode]);

  return <Canvas ref={canvasRef} />;
};

export default AnimatedBackground; 